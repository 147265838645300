export default {
  documentTitle: {
    id: 'termsPage.documentTitle',
    defaultMessage: 'Terms of use - Choicely'
  },
  termsOfUse: {
    id: 'termsPage.termsOfUse',
    defaultMessage: `
      <h1>TERMS OF USE</h1>
      <p>
        <strong>These Terms of Use are effective on April 25th, 2024</strong>
      </p>
      <p>
        Please read these terms of use carefully (“Terms”). These Terms govern and apply to your access and use of www.choicely.com and Choicely’s social networking services available via Choicely’s site and Choicely’s mobile apps (collectively, the “Service”). By accessing or using our Service, you agree to be bound all of the terms and conditions described in these Terms. If you do not agree to all of these terms and conditions, do not use our Service.
      </p>
      <h3>BASIC TERMS</h3>
      <dl>
        <dt>You must be at least 13 years old to use the Service.</dt>
        <dt>You may not post violent, nude, partially nude, discriminatory, unlawful, infringing, hateful, pornographic or sexually suggestive photos or other content via the Service.</dt>
        <dt>You are responsible for any activity that occurs through your account and you agree you will not sell, transfer, license or assign your account, followers, username, or any account rights. With the exception of people or businesses that are expressly authorized to create accounts on behalf of their employers or clients, Choicely prohibits the creation of and you agree that you will not create an account for anyone other than yourself. You also represent that all information you provide or provided to Choicely upon registration and at all other times will be true, accurate, current and complete and you agree to update your information as necessary to maintain its truth and accuracy.</dt>
        <dt>You agree that you will not solicit, collect or use the login credentials of other Choicely users.</dt>
        <dt>You are responsible for keeping your password secret and secure.</dt>
        <dt>You may not use the Service for any illegal or unauthorized purpose. You agree to comply with all laws, rules and regulations.</dt>
        <dt>You are solely responsible for your conduct and any data that you submit, post or display on or via the Service.</dt>
        <dt>You must not create accounts with the Service through unauthorized means, including but not limited to, by using an automated device, script, bot, spider, crawler or scraper.</dt>
        <dt>One user can only use one account when participating into contests.</dt>
      </dl>
      <p>
        Violation of these Terms of Use may, in Choicely’s sole discretion, result in termination of your Choicely account. You understand and agree that Choicely cannot and will not be responsible for the Content posted on the Service and you use the Service at your own risk. If you violate the letter or spirit of these Terms of Use, or otherwise create risk or possible legal exposure for Choicely, we can stop providing all or part of the Service to you.
      </p>
      <h3>USE OF THE SERVICE</h3>
      <p>
        Choicely allows you to access, view and share moments with your friends through our mobile and web-based apps and our site (each an “App”). As long as you comply with these Terms, you have the right to download and install a copy of the App to your mobile device, and to access and use the Service, for your own personal use. You may not: (i) copy, modify or distribute the App for any purpose; (ii) transfer, sublicense, lease, lend, rent or otherwise distribute the App or the Service to any third party; (iii) decompile, reverse-engineer, disassemble, or create derivative works of the App or the Service; (iv) make the functionality of the App or the Service available to multiple users through any means; or (v) use the Service in any unlawful manner, for any unlawful purpose, or in any manner inconsistent with these Terms.
      </p>
      <p>
        The following terms apply to any App accessed through or downloaded from any app store or distribution platform (like the Apple App Store or Google Play) where the App is made available (each an “App Provider”). You acknowledge and agree that:
      </p>
      <ul>
        <li>These Terms are concluded between you and Choicely, and not with the App Provider, and that Choicely Oy (not the App Provider), is solely responsible for the App.</li>
        <li>The App Provider has no obligation to furnish any maintenance and support services with respect to the App.</li>
        <li>In the event of any failure of the App to conform to any applicable warranty, you may notify the App Provider, and the App Provider will refund the purchase price for the App to you (if applicable) and to the maximum extent permitted by applicable law, the App Provider will have no other warranty obligation whatsoever with respect to the App. Any other claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty will be the sole responsibility of Choicely.</li>
        <li>The App Provider is not responsible for addressing any claims you have or any claims of any third party relating to the App or your possession and use of the App, including, but not limited to: (i) product liability claims; (ii) any claim that the App fails to conform to any applicable legal or regulatory requirement; and (iii) claims arising under consumer protection or similar legislation.</li>
        <li>In the event of any third party claim that the App or your possession and use of that App infringes that third party’s intellectual property rights, Choicely will be solely responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement claim to the extent required by these Terms.</li>
        <li>The App Provider, and its subsidiaries, are third party beneficiaries of these Terms as related to your license of the App, and that, upon your acceptance of the terms and conditions of these Terms, the App Provider will have the right (and will be deemed to have accepted the right) to enforce these Terms as related to your license of the App against you as a third party beneficiary thereof.</li>
        <li>You must also comply with all applicable third party terms of service when using the App.</li>
      </ul>
      <p>
        Our Service may change from time to time and/or we may stop (permanently or temporarily) providing the Service (or features within the Service), possibly without prior notice to you. Our Service may, at some point, include advertisements, which may be targeted to the content or information on the Service, queries made through the Service, or from other information. The types and extent of advertising on the Service are also subject to change over time. In consideration for providing you the Service, you agree that we and our third party providers and partners may place advertising on our Service or in connection with the display of content or information on our Service. Certain features of our Service may be subject to additional terms or conditions, which will be posted with those features.
      </p>
      <h3>RIGHTS</h3>
      <dl>
        <dt>Choicely does not claim ownership of any photo content that you post on or through the Service.</dt>
        <dt>Choicely has the right to commercialize all information such as “loves” or ”comments” through the Service.</dt>
        <dt>Some of the Service is supported by advertising revenue and may display advertisements and promotions, and you hereby agree that Choicely may place such advertising and promotions on the Service or on, about, or in conjunction with your Content. The manner, mode and extent of such advertising and promotions are subject to change without specific notice to you.</dt>
        <dt>You acknowledge that we may not always identify paid services, sponsored content, or commercial communications as such.</dt>
        <dt>You represent and warrant that: (i) you own the Content posted by you on or through the Service or otherwise have the right to grant the rights and licenses set forth in these Terms of Use; (ii) the posting and use of your Content on or through the Service does not violate, misappropriate or infringe on the rights of any third party, including, without limitation, privacy rights, publicity rights, copyrights, trademark and/or other intellectual property rights; (iii) you agree to pay for all royalties, fees, and any other monies owed by reason of Content you post on or through the Service; and (iv) you have the legal right and capacity to enter into these Terms of Use in your jurisdiction.</dt>
        <dt>The Choicely name and logo are trademarks of Choicely Oy, and may not be copied, imitated or used, in whole or in part, without the prior written permission of Choicely.</dt>
        <dt>Although it is Choicely’s intention for the Service to be available as much as possible, there will be occasions when the Service may be interrupted, including, without limitation, for scheduled maintenance or upgrades, for emergency repairs, or due to failure of telecommunications links and/or equipment. Also, Choicely reserves the right to remove any Content from the Service for any reason, without prior notice. You also acknowledge that the Internet may be subject to breaches of security and that the submission of Content or other information may not be secure.</dt>
        <dt>You agree that Choicely is not responsible for, and does not endorse, Content posted within the Service. Choicely does not have any obligation to prescreen, monitor, edit, or remove any Content. If your Content violates these Terms of Use, you may bear legal responsibility for that Content.</dt>
        <dt>Except as otherwise described in the Service’s Privacy Policy, available at http://choicely.com/privacy/, as between you and Choicely, any Content will be non-confidential and non-proprietary and we will not be liable for any use or disclosure of Content. You acknowledge and agree that your relationship with Choicely is not a confidential, fiduciary, or other type of special relationship, and that your decision to submit any Content does not place Choicely in a position that is any different from the position held by members of the general public, including with regard to your Content. None of your Content will be subject to any obligation of confidence on the part of Choicely, and Choicely will not be liable for any use or disclosure of any Content you provide.</dt>
      </dl>
      <p>
        If, despite our policy, you choose to send us content, information, ideas, suggestions, or other materials, you further agree that Choicely is free to use any such content, information, ideas, suggestions or other materials, for any purposes whatsoever, including, without limitation, developing and marketing products and services, without any liability or payment of any kind to you.
      </p>
      <h3>CHOICELY CONTESTS AND VOTE CONTESTS</h3>
      <dl>
        <dt>The photo that has been chosen as the winner of a contest, automatically grants its rights to the organizer of the contest as non-exclusive, fully paid and royalty-free, transferable, sub-licensable, worldwide license to use the photo, subject to Service’s Privacy Policy.</dt>
        <dt>The winner of a contest or a vote contest can be chosen various ways. The organizer of a contest or a vote contest determines in which way the winning photo will be choses. The organizer has three (3) different options to choose from and the organizer can choose any mix of these below methods to rate the contest photos. However, once the organizer has chosen one method to rate the photos, the organizer can not change the method during the event.
          <dl>
            <dt>Most popular in where user who has submitted the most “liked” photo wins</dt>
            <dt>Random in where a random win will be chosen among the photos  that have been uploaded to the contest or to the voting poll</dt>
            <dt>Organizer decides in where the organizer of a contest or a vote contest   decides which photo will be chosen to win.</dt>
          </dl>
        </dt>
        <dt>The organizer of the photo contest can show the published photos on the organizer website, shops and marketing venues and events.</dt>
        <dt>The organizer of the Choicely photo contest can ask permission to buy the rights of any photos published in the service. The fee of each photo shall be fixed 10 USD per photo. Publisher of the photo has the right to accept or not to accept the proposal to buy photo. The publisher needs to accept the before the rights of the photo will be transferred the organizer.</dt>
        <dt>Choicely shall take 30 per cent transaction fee of each accomplished and agreed photo purchase.</dt>
        <dt>If the organizer wants to cancel a contest or a vote contest during the event, he or she needs to contact Choicely team.  However, the organizer can modify the details of a contest or a vote contest during the contest or a vote contest during the event.</dt>
      </dl>
      <h3>CREATING A CHOICELY ACCOUNT</h3>
      <p>
        In order to access and use Choicely, you will need to register and create an account (your “Account”). By creating an Account, you represent that you are of legal age to form a binding contract and are not a person barred from receiving services under the laws of Finland or other applicable jurisdiction. When creating an Account, you may be required to provide certain personal information about yourself and will establish a username and a password. You agree to provide accurate, current and complete information about your Account.
      </p>
      <p>
        We reserve the right to suspend or terminate your Account if any information provided during the registration process or thereafter proves to be inaccurate, false or misleading or to reclaim any username that you create through the Service that violates our Terms. You are responsible for maintaining the confidentiality of your password and Account, and agree to notify us if your password is lost, stolen, or disclosed to an unauthorized third party, or otherwise may have been compromised. You are responsible for activities that occur under your Account.
      </p>
      <p>
        You can link your Account to various webmail, social networking, and other online accounts. You may only link your own accounts. We will access and retrieve your account information from these sites on your behalf (in some cases, by using your username and password) to make certain information or services available to you through the Service.
      </p>
      <h3>PRIVACY</h3>
      <p>
        Please refer to our Privacy Policy for information on how we collect, use and disclose information from our users.
      </p>
      <h3>CONTENT SUBMISSIONS</h3>
      <p>
        Our Service allows you and other users to post, link, store, share and otherwise make available certain information, images, videos, text and/or other content (“Content”). You are responsible for the Content that you post to the Service, including its legality, reliability, and appropriateness. By posting Content to the Service, you grant us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through the Service. You agree that this license includes the right for us to make your Content available to other users of the Service, who may also use your Content subject to these Terms.
      </p>
      <p>
        You can remove Content that you posted by specifically deleting it. In certain instances, however, some Content (such as posts or comments you make) may not be completely removed and copies of your Content may continue to exist on the Service and/or elsewhere. We are not responsible or liable for the removal or deletion of (or the failure to remove or delete) any Content on the Service.
      </p>
      <p>
        You represent and warrant that: (i) the Content is yours (you own it) or you have the right to use it and grant us the rights and license as provided in these Terms, and (ii) the posting of your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person.
      </p>
      <p>
        We ask that you respect our on-line community and other users when posting Content and using the Service.
      </p>
      <p>
        We don’t have an obligation to monitor your access to or use of the Service or to review or edit any Content, but we have the right to do so for the purpose of operating the Service, to ensure your compliance with these Terms, or to comply with applicable law or the order or requirement of a court, administrative agency or other governmental body. We reserve the right, at any time and without prior notice, to remove or disable access to any Content, that we consider, in our sole discretion, to be in violation of these Terms or otherwise harmful to the Service.
      </p>
      <h3>CONTENT ON THE SERVICE</h3>
      <p>
        The Content available through the Service has not been reviewed, verified or authenticated by us, and may include inaccuracies or false information. We make no representations, warranties, or guarantees in connection with our Service or any Content on the Service, relating to the quality, suitability, truth, accuracy or completeness of any content contained in the Service. You acknowledge sole responsibility for and assume all risk arising from your use or reliance of any Content.
      </p>
      <h3>CHOICELY SERVICES AND PREMIUM CONTENT</h3>
      <p>Your Rights. Subject to the terms and conditions of this Agreement, you are given a personal license and right to access and use the applicable Services (and in the case of an App, to also install the App on your device) (collectively, the “Subscription”). This Subscription is nonexclusive, non-transferable, and non-sublicensable, and the Subscription only lasts while this Agreement remains in effect. As used herein, a “Paid Subscription” means a Subscription to any fee-based portions of the Services. </p>
      <p>Restricted Access. The Services are delivered electronically, and shall be deemed accepted by you upon delivery. Some modules, tools, features, or other functionality (collectively, “Features”) may only be available to Paid Subscriptions. Paid Subscriptions themselves shall also be subject to whatever limitations may be specified in the corresponding Paid Subscription plan or package (such as limitation on the number of authorized users, usage volume or duration). </p>
      <p>Changes to the Services. Choicely reserves the right, from time to time and in its discretion, and without prior notice, to: (a) change any Features and/or other aspects (such as the design, layout, or availability) of the Services; (b) deactivate any Features, including without limitation deactivating any free Features and offering them only as part of a Paid Subscription (and vice - 2 - versa); and (c) stop supporting (or limit compatibility with) certain devices and operating systems, in which case your access to the Services on such devices or operating systems may be impaired. Where required by applicable law, we will notify you in advance (either via email, via your Account, and/or within the Services) in the event we decide to shut down or discontinue any App, Web Service, and/or the Services as a whole. </p>
      <p>Reservation of Rights. For the avoidance of doubt, the Services are only licensed to you, and no title in them passes to you. Any rights not expressly granted herein are hereby reserved by Choicely and its licensors, and you are granted no other right or license in the Services, whether by implied license, estoppel, exhaustion, operation of law, or otherwise.</p>
      <h3>PAYMENT</h3>
      <p>Fees. Certain Services and Features are only offered under a Paid Subscription, and certain Service Content is only offered for payment (such as Premium Content). You agree to pay whatever fees and other charges are presented to you when you subscribe to a Paid Subscription or paid Service Content (collectively, the “Fees”). If the payment plan is on a recurring-subscription basis, you agree to pay the Fees in accordance with the applicable billing cycle (“Billing Cycle”). If you downloaded the App from the Apple Store, refunds are permissible in accordance with their refund policy; otherwise refunds are permissible in accordance with our Refund Policy, which is incorporated into this Agreement by reference. Except as stated in those policies, all Fees are non-refundable and non-cancellable. Moreover, amounts payable are exclusive of all applicable sales, use, consumption, VAT and other taxes, except for taxes based upon Choicely’s net income. </p>
      <p>Payment Methods and Processing. Payments may be processed via the relevant App Marketplace, as well as any other third-party payment methods which we make available (such as via Stripe and certain supported payment cards). You must provide accurate billing information, and promptly update any changes to it (such as card numbers and expiry dates). If you are paying via credit or debit card, you represent that you are the authorized user of the card, and you authorize Choicely (and any third party payment processor) to collect payment from you, on a recurring basis (if applicable), and to take all other necessary billing actions. If payment is made via a third-party payment processor, you will also be subject to its terms and conditions (over which we have no control) – so carefully read those terms. With respect to direct credit-card Web Service purchases, Choicely Inc. is responsible for the execution of your transaction and will initiate your payment.</p>
      <h3>COPYRIGHT POLICY</h3>
      <p>
        We respect other people’s rights, and expect you to do the same. We do not permit copyright infringing activities and infringement of intellectual property rights on the Service and will remove any such Content if properly notified that such Content infringes on another’s intellectual property rights. If you repeatedly infringe other people’s intellectual property rights, we will disable your account when appropriate.
      </p>
      <h3>UNAUTHORIZED ACTIVITIES</h3>
      <p>
        Our Service may be used and accessed for lawful purposes only. You agree that you will not do any of the following while using or accessing the Service: (i) attempt to access or search the Service or download Content from the Service through the use of any engine, software, tool, agent, device or mechanism (including spiders, robots, crawlers, data mining tools or the like) other than the software and/or search agents provided by us or other generally available third party web browsers; (ii) access, tamper with, or use non-public areas of the Service, our computer systems, or the technical delivery systems of our providers; (iii) gather and use information, such as other users’ names, real names, email addresses, available through the Service to transmit any unsolicited advertising, junk mail, spam or other form of solicitation; (iv) use the Service for any commercial purpose or for the benefit of any third party or in any manner not by these Terms; (v) violate any applicable law or regulation; or (vi) encourage or enable any other individual to do any of the foregoing. We reserve the right to investigate and prosecute violations of any of the above and/or involve and cooperate with law enforcement authorities in prosecuting users who violate these Terms.
      </p>
      <h3>INDEMNITY</h3>
      <p>
        You agree to indemnify and hold us harmless from and against any and all costs, damages, liabilities, and expenses (including attorneys’ fees) we incur in relation to, arising from, or for the purpose of avoiding, any claim or demand from a third party that your use of the Service or the use of the Service by any person using your Account violates any applicable law or regulation, or the rights of any third party.
      </p>
      <h3>LINKS TO THIRD PARTY SITES</h3>
      <p>
        The Service may include links to other sites and services that are not operated by us. We are providing these links to you only as a convenience and are not responsible for the content or links displayed on such sites. You are responsible for and assume all risk arising from your use or reliance of any third party sites.
      </p>
      <h3>OWNERSHIP</h3>
      <p>
        Our App and Service is protected by copyright, trademark, and other laws of Finland and foreign countries. Except as expressly provided in these Terms, we (or our licensors) exclusively own all right, title and interest in and to the App and the Service, including all associated intellectual property rights. You may not remove, alter or obscure any copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying the App or the Service, including in any Content. You acknowledge and agree that any feedback, comments or suggestions you may provide regarding the App or the Service (“Feedback”) will be the sole and exclusive property of Choicely and you hereby irrevocably assign to us all of your right, title and interest in and to all Feedback.
      </p>
      <h3>TERMINATION</h3>
      <p>
        If you breach any of the terms of these Terms, we have the right to suspend or disable your access to or use of the App and/or Service.
      </p>
      <h3>DISCLAIMER</h3>
      <p>
        You understand and agree that the App and the Service are provided to you “AS IS” and on an “AS AVAILABLE” basis. Without limiting the foregoing, WE EXPLICITLY DISCLAIM ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT OR NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. We make no warranty that the App or the Service will meet your requirements or be available on an uninterrupted, secure, or error-free basis.
      </p>
      <h3>LIMITATION OF LIABILITY</h3>
      <p>
        OUR TOTAL LIABILITY TO YOU FROM ALL CAUSES OF ACTION AND UNDER ALL THEORIES OF LIABILITY WILL BE LIMITED TO THE AMOUNT YOU PAID FOR THE APP AND IN NO EVENT WILL IT EXCEED $50. WE WILL NOT BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING WITHOUT LIMITATION, LOSS OF PROFITS, DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM (i) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE APP AND/OR SERVICE; (ii) ANY CONDUCT OR CONTENT OF ANY THIRD PARTY ON THE SERVICE; (iii) ANY CONTENT OBTAINED FROM THE SERVICE; AND (iv) UNAUTHORIZED ACCESS, USE OR ALTERATION OF YOUR TRANSMISSIONS OR CONTENT, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE) OR ANY OTHER LEGAL THEORY, WHETHER OR NOT WE HAVE BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, AND EVEN IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.
      </p>
      <h3>EXCLUSIONS</h3>
      <p>
        Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or limitation of liability for consequential or incidental damages, so the limitations above may not apply to you.
      </p>
      <h3>GENERAL</h3>
      <p>
        These Terms are governed by the laws of Finland, without regard to any conflict of laws rules or principles. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service. We may revise these Terms from time to time, and we will try to provide at least 30 days notice prior to any new terms taking effect. By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms.
      </p>
      <h3>QUESTIONS & CONTACT INFORMATION</h3>
      <p>
        Please contact us via <a href="mailto:jari@choicely.com" class="link">jari@choicely.com</a> if you have any questions about our Terms.
      </p>
    `
  },

  'form.gdpr': {
    id: 'form.gdpr',
    defaultMessage: `
      <h3>GDPR COMPLIANCE</h3>
      <h4>WHAT RIGHTS DO I HAVE AS A CONSUMER?</h4>
      <ul>
        <li><strong>The right of access</strong> – Individuals will have the right to know exactly what information is held about them and how it is processed.</li>
        <li><strong>The right of rectification</strong> – Individuals will be entitled to have personal data rectified if it is inaccurate or incomplete.</li>
        <li><strong>The right to erasure</strong> – Also known as ‘the right to be forgotten’, this refers to an individual’s right to having their personal data deleted or removed without the need for a specific reason as to why they wish to discontinue.</li>
        <li><strong>The right to restrict processing</strong> – Refers to an individual’s right to block or suppress processing of their personal data.</li>
        <li><strong>The right to data portability</strong> – This allows individuals to retain and reuse their personal data for their own purpose.</li>
        <li><strong>The right to object</strong> – In certain circumstances, individuals are entitled to object to their personal data being used. This includes, if a company uses personal data for the purpose of direct marketing, scientific and historical research, or for the performance of a task in the public interest.</li>
      </ul>
      <h4>WHAT DATA DOES CHOICELY COLLECT?</h4>
      <p>Choicely collects 3 types of data from users:</p>
      <ul>
        <li><strong>Personal Data:</strong> Personally identifiable information, such as your name, email address, and telephone number, that you voluntarily give to us when you register with the Site or when you choose to participate in various activities related to the Site, such as online contests and commenting boards. You are under no obligation to provide us with personal information of any kind, however, your refused to do so many prevent you from using certain features of the Site.</li>
        <li><strong>Derivative Data:</strong> Information our servers automatically collect when you access the Site, such as your IP address, your browser type, your operating system, your access times, and the pages you have viewed directly before and after accessing Site.</li>
        <li><strong>Data From Contests and Surveys:</strong> Personal and other information you may provide when entering contests and/or responding to surveys.</li>
      </ul>
      <h4>CAN I AVOID COLLECTING PERSONAL DATA WHEN USING THE CHOICELY WEBSITE?</h4>
      <p>Users have the option to Decide which cookies you want to allow ( Essential, Functionality, Analytics, Advertising) via the Notification present on our website.</p>
      <p>Users may contact Choicely´s dedicated GDPR support team to discuss other options for minimizing the collection of personal data where possible. Please keep in mind that certain information is absolutely necessary to experience the full functionality of our site and services.</p>
      <h4>HOW LONG DOES CHOICELY KEEP MY DATA?</h4>
      <p>Choicely will keep collected data (such as email address, telephone number etc.) as long as any of your purchases are still under warranty contract. We store and keep this data in order to facilitate communication and prevent fraudulent behaviour.</p>
      <h4>HOW CAN I CHECK HOW MY DATA IS BEING HANDLED?</h4>
      <p>You can send us a message using the above form and our Data Privacy Officer will reply to you within 48h</p>
    `
  }
}
